import * as i0 from '@angular/core';
import { InjectionToken, Injectable, input, Injector, ViewContainerRef, Directive, HostBinding, HostListener, forwardRef, ElementRef, Optional, Inject, signal, computed, Component, Type, ViewChild, inject, Host, Input, ContentChild, output, effect, viewChild, contentChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/datepicker';
import { MatRangeDateSelectionModel, MatDateSelectionModel, DateRange, yearsPerPage, MatCalendar, MAT_DATE_RANGE_SELECTION_STRATEGY, DefaultMatCalendarRangeStrategy, MatDatepickerModule, MatMonthView, MatSingleDateSelectionModel } from '@angular/material/datepicker';
import { WLCM_FORM_FIELD, WLCM_FORM_CONTROL_PROVIDER, WLCM_INPUT_BINDER, WlcmFormsModule } from '@wlcm/angular/forms';
import { merge, EMPTY, filter, BehaviorSubject, switchMap, fromEvent, map, Subject, tap, startWith, takeUntil } from 'rxjs';
import * as i3 from '@angular/forms';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlContainer } from '@angular/forms';
import * as i1$1 from '@angular/material/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { __decorate, __metadata } from 'tslib';
import { format, isBefore, startOfDay, isAfter, parse, isValid, endOfMonth, add, startOfMonth } from 'date-fns';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import * as i1 from '@angular/cdk/overlay';
import { OverlayRef, OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import * as i2$1 from '@wlcm/angular/button';
import { WlcmButtonModule } from '@wlcm/angular/button';
import { WlcmIconName, WlcmIconDirective } from '@wlcm/angular/core';
function WlcmCalendarHeaderComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function WlcmCalendarHeaderComponent_Conditional_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.previousClicked());
    });
    i0.ɵɵelementContainer(1, 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r1.disableLeftArrow());
    i0.ɵɵadvance();
    i0.ɵɵproperty("wlcmIcon", ctx_r1.WlcmIconName.CHEVRON_DOWN);
  }
}
function WlcmCalendarHeaderComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function WlcmCalendarHeaderComponent_Conditional_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.nextClicked());
    });
    i0.ɵɵelementContainer(1, 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r1.disableRightArrow());
    i0.ɵɵadvance();
    i0.ɵɵproperty("wlcmIcon", ctx_r1.WlcmIconName.CHEVRON_DOWN);
  }
}
const _c0 = ["bottomPanelContainer"];
const _c1 = [[["", "wlcmDateRangePickerInputStart", ""]], [["", "wlcmDateRangePickerInputEnd", ""]]];
const _c2 = ["[wlcmDateRangePickerInputStart]", "[wlcmDateRangePickerInputEnd]"];
const _c3 = [[["", "wlcmDatepickerInput", ""]]];
const _c4 = ["[wlcmDatepickerInput]"];
const WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL = new InjectionToken('WLCM_DATE_RANGE_SELECTION_MODEL');
const WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL_PROVIDER = {
  provide: WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL,
  useFactory: adapter => new MatRangeDateSelectionModel(adapter),
  deps: [DateAdapter]
};
function range(length, valueFunction) {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
class WlcmDateAdapter extends DateFnsAdapter {
  getDayOfWeekNames(style) {
    const formatString = style === 'long' ? 'EEEE' : 'EEEEEE';
    return range(7, i => format(new Date(2017, 0, i + 1), formatString));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵWlcmDateAdapter_BaseFactory;
      return function WlcmDateAdapter_Factory(t) {
        return (ɵWlcmDateAdapter_BaseFactory || (ɵWlcmDateAdapter_BaseFactory = i0.ɵɵgetInheritedFactory(WlcmDateAdapter)))(t || WlcmDateAdapter);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WlcmDateAdapter,
      factory: WlcmDateAdapter.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDateAdapter, [{
    type: Injectable
  }], null, null);
})();
var WlcmDateValidation;
(function (WlcmDateValidation) {
  WlcmDateValidation["Format"] = "WlcmInvalidDateFormat";
  WlcmDateValidation["MinDate"] = "WlcmDateIsBeforeMinDate";
  WlcmDateValidation["MaxDate"] = "WlcmDateIsAfterMaxDate";
})(WlcmDateValidation || (WlcmDateValidation = {}));
const WLCM_DEFAULT_DATE_FORMATS = {
  parse: {
    dateInput: 'MMM/dd/yyyy'
  },
  display: {
    dateInput: 'MMM/dd/yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'MMM/dd/yyyy',
    monthYearA11yLabel: 'MMM yyyy'
  }
};
const WLCM_DEFAULT_DATEPICKER_PROVIDERS = [{
  provide: MAT_DATE_LOCALE,
  useValue: enUS
}, {
  provide: MAT_DATE_FORMATS,
  useValue: WLCM_DEFAULT_DATE_FORMATS
}, {
  provide: DateAdapter,
  useClass: WlcmDateAdapter,
  deps: [MAT_DATE_LOCALE]
}];
const WLCM_DATEPICKER_CONFIG = new InjectionToken('WLCM_DATEPICKER_CONFIG', {
  providedIn: 'root',
  factory: () => ({
    selectOnly: false
  })
});
const WLCM_DATEPICKER_INPUT_CLASS = 'wlcm-datepicker-input';
let WlcmDatepickerTrigger = class WlcmDatepickerTrigger {
  get isOnlySelect() {
    return this.selectOnly();
  }
  constructor(overlay, viewContainerRef, selectionModel) {
    this.overlay = overlay;
    this.viewContainerRef = viewContainerRef;
    this.selectionModel = selectionModel;
    this.minDate = input(this.defaultDatepickerConfig.minDate?.());
    this.maxDate = input(this.defaultDatepickerConfig.maxDate?.());
    this.selectOnly = input(this.defaultDatepickerConfig.selectOnly || false);
    this.overlayRef = this.overlay.create(this.createOverlayConfig());
    this.handleBackdropClick();
  }
  open() {
    const injector = Injector.create({
      providers: [{
        provide: OverlayRef,
        useValue: this.overlayRef
      }],
      parent: this.datepickerInjector
    });
    const portal = new ComponentPortal(this.componentType, this.viewContainerRef, injector);
    this.updatePositionStrategy();
    this.componentRef = this.overlayRef.attach(portal);
  }
  close() {
    this.overlayRef.detach();
  }
  get isOpen() {
    return this.overlayRef.hasAttached();
  }
  get opened() {
    return merge(this.overlayRef?.attachments() || EMPTY).pipe(filter(() => this.overlayRef.hasAttached()));
  }
  get closed() {
    return (this.overlayRef?.detachments() || EMPTY).pipe(filter(() => !this.overlayRef.hasAttached()));
  }
  get datepickerInjector() {
    const parent = this.viewContainerRef.injector;
    return Injector.create({
      parent,
      providers: [{
        provide: WLCM_DATEPICKER_CONFIG,
        useValue: this.datepickerConfig
      }]
    });
  }
  get defaultDatepickerConfig() {
    return this.viewContainerRef.injector.get(WLCM_DATEPICKER_CONFIG, {}, {
      optional: true
    });
  }
  get datepickerConfig() {
    const config = this.defaultDatepickerConfig;
    if (this.minDate()) config.minDate = this.minDate;
    if (this.maxDate()) config.maxDate = this.maxDate;
    config.selectOnly = this.selectOnly();
    return config;
  }
  handleBackdropClick() {
    this.overlayRef.backdropClick().pipe(untilDestroyed(this)).subscribe(() => this.close());
  }
  updatePositionStrategy() {
    const strategy = this.overlay.position().flexibleConnectedTo(this.connectedTo);
    strategy.withPositions([{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -4
    }]);
    strategy.withLockedPosition(false);
    strategy.withDefaultOffsetY(4);
    this.overlayRef.updatePositionStrategy(strategy);
  }
  createOverlayConfig() {
    return new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      backdropClass: 'transparent'
    });
  }
  static {
    this.ɵfac = function WlcmDatepickerTrigger_Factory(t) {
      return new (t || WlcmDatepickerTrigger)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i2.MatDateSelectionModel));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmDatepickerTrigger,
      hostVars: 2,
      hostBindings: function WlcmDatepickerTrigger_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("wlcm-datepicker-select-only-mode", ctx.isOnlySelect);
        }
      },
      inputs: {
        minDate: [i0.ɵɵInputFlags.SignalBased, "minDate"],
        maxDate: [i0.ɵɵInputFlags.SignalBased, "maxDate"],
        selectOnly: [i0.ɵɵInputFlags.SignalBased, "selectOnly"]
      }
    });
  }
};
WlcmDatepickerTrigger = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Overlay, ViewContainerRef, MatDateSelectionModel])], WlcmDatepickerTrigger);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerTrigger, [{
    type: Directive
  }], () => [{
    type: i1.Overlay
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i2.MatDateSelectionModel
  }], {
    isOnlySelect: [{
      type: HostBinding,
      args: ['class.wlcm-datepicker-select-only-mode']
    }]
  });
})();
class WlcmDatepickerInputBase {
  handleBlur() {
    this.updateSelection();
    this._touched?.();
  }
  handleInput() {
    if (this.isValidFormat(this.currentValue, this.dateFormats.parse.dateInput)) {
      this.updateSelection();
    }
    this._validatorChanged?.();
  }
  get datepickerInputClass() {
    return WLCM_DATEPICKER_INPUT_CLASS;
  }
  constructor(element, dateAdapter, dateFormats, trigger) {
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.trigger = trigger;
  }
  writeValue(value) {
    this.updateInputValue(value);
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  registerOnValidatorChange(callback) {
    this._validatorChanged = callback;
  }
  validate() {
    if (!this.currentValue) return null;
    const isValid = this.isValidFormat(this.currentValue, this.dateFormats.parse.dateInput);
    if (!isValid) {
      return {
        [WlcmDateValidation.Format]: {
          value: this.currentValue,
          format: this.dateFormats.parse.dateInput
        },
        required: false
      };
    }
    const date = this.parseCurrentValue();
    if (this.minDate && isBefore(startOfDay(date), startOfDay(this.minDate))) {
      return {
        [WlcmDateValidation.MinDate]: {
          minDate: this.minDate,
          currentDate: date
        }
      };
    }
    if (this.maxDate && isAfter(startOfDay(date), startOfDay(this.maxDate))) {
      return {
        [WlcmDateValidation.MaxDate]: {
          maxDate: this.maxDate,
          currentDate: date
        }
      };
    }
    return null;
  }
  parseCurrentValue() {
    const date = this.dateAdapter.parse(this.currentValue, this.dateFormats.parse.dateInput);
    return this.dateAdapter.getValidDateOrNull(date);
  }
  isValidFormat(value, pattern) {
    const parsedDate = parse(value, pattern, new Date());
    return isValid(parsedDate) && value === format(parsedDate, pattern);
  }
  get minDate() {
    return this.trigger.minDate?.();
  }
  get maxDate() {
    return this.trigger.maxDate?.();
  }
  get inputElement() {
    return this.element.nativeElement;
  }
  get currentValue() {
    return this.inputElement.value;
  }
  updateInputValue(value) {
    let newValue = '';
    if (value) {
      newValue = this.dateAdapter.format(value, this.dateFormats.display.dateInput);
    }
    this.inputElement.value = newValue;
  }
  static {
    this.ɵfac = function WlcmDatepickerInputBase_Factory(t) {
      i0.ɵɵinvalidFactory();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmDatepickerInputBase,
      hostVars: 2,
      hostBindings: function WlcmDatepickerInputBase_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("blur", function WlcmDatepickerInputBase_blur_HostBindingHandler() {
            return ctx.handleBlur();
          })("input", function WlcmDatepickerInputBase_input_HostBindingHandler() {
            return ctx.handleInput();
          });
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.datepickerInputClass);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerInputBase, [{
    type: Directive
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined
  }, {
    type: WlcmDatepickerTrigger
  }], {
    handleBlur: [{
      type: HostListener,
      args: ['blur']
    }],
    handleInput: [{
      type: HostListener,
      args: ['input']
    }],
    datepickerInputClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class WlcmDateRangePickerInputBase extends WlcmDatepickerInputBase {
  constructor(element, dateAdapter, dateFormats, trigger) {
    super(element, dateAdapter, dateFormats, trigger);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.trigger = trigger;
    this._inputMirror = this.createInputMirror();
  }
  ngAfterViewInit() {
    this.inputElement.parentElement?.appendChild(this._inputMirror);
    this.copyInputElementStyles(this._inputMirror);
    document.fonts.ready.then(() => this.changeInputSize());
  }
  writeValue(value) {
    super.writeValue(value);
    this.updateSelection();
  }
  handleInput() {
    this.changeInputSize();
    super.handleInput();
  }
  updateInputValue(value) {
    super.updateInputValue(value);
    this.changeInputSize();
  }
  changeInputSize() {
    requestAnimationFrame(() => {
      const value = this.currentValue || this.inputElement.placeholder;
      this._inputMirror.innerHTML = value;
      this.inputElement.style.width = `${this._inputMirror.offsetWidth + 2}px`;
    });
  }
  createInputMirror() {
    const inputMirror = document.createElement('div');
    inputMirror.classList.add('wlcm-date-range-picker-input-field-mirror');
    return inputMirror;
  }
  copyInputElementStyles(target) {
    const inputElementStyles = window.getComputedStyle(this.inputElement);
    target.style.fontSize = inputElementStyles.fontSize;
    target.style.fontFamily = inputElementStyles.fontFamily;
    target.style.letterSpacing = inputElementStyles.letterSpacing;
  }
  static {
    this.ɵfac = function WlcmDateRangePickerInputBase_Factory(t) {
      i0.ɵɵinvalidFactory();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmDateRangePickerInputBase,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDateRangePickerInputBase, [{
    type: Directive
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined
  }, {
    type: WlcmDatepickerTrigger
  }], null);
})();
var WlcmDateRangeValidation;
(function (WlcmDateRangeValidation) {
  WlcmDateRangeValidation["StartExceedsEnd"] = "wlcmDateRangeStartExceedsEnd";
})(WlcmDateRangeValidation || (WlcmDateRangeValidation = {}));
let DateRangePickerInputStartDirective = class DateRangePickerInputStartDirective extends WlcmDateRangePickerInputBase {
  constructor(element, dateAdapter, dateFormats, selectionModel, trigger) {
    super(element, dateAdapter, dateFormats, trigger);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.selectionModel = selectionModel;
    this.trigger = trigger;
    this.updateSelection = () => {
      let start = this.parseCurrentValue();
      this.selectionModel.updateSelection(new DateRange(start, this.selectionModel.selection.end), this);
    };
    this.handleSelectionChange();
  }
  validate() {
    const defaultErrors = super.validate();
    if (this.selectionModel.isComplete() && isValid(this.selectionModel.selection.end)) {
      const startDate = this.parseCurrentValue();
      if (startDate && isAfter(startDate, this.selectionModel.selection.end)) {
        return {
          [WlcmDateRangeValidation.StartExceedsEnd]: true,
          ...(defaultErrors ?? {})
        };
      }
    }
    return defaultErrors;
  }
  handleSelectionChange() {
    this.selectionModel.selectionChanged.pipe(untilDestroyed(this)).subscribe(({
      selection
    }) => {
      const validDate = this.dateAdapter.getValidDateOrNull(selection.start);
      this._changed?.(validDate);
      this.updateInputValue(validDate);
      this._validatorChanged?.();
    });
  }
  static {
    this.ɵfac = function DateRangePickerInputStartDirective_Factory(t) {
      return new (t || DateRangePickerInputStartDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(MAT_DATE_FORMATS, 8), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DateRangePickerInputStartDirective,
      selectors: [["", "wlcmDateRangePickerInputStart", ""]],
      hostAttrs: [1, "wlcm-date-range-picker-input-field"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerInputStartDirective),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateRangePickerInputStartDirective),
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
};
DateRangePickerInputStartDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, DateAdapter, Object, MatDateSelectionModel, WlcmDatepickerTrigger])], DateRangePickerInputStartDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateRangePickerInputStartDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmDateRangePickerInputStart]',
      host: {
        class: 'wlcm-date-range-picker-input-field'
      },
      standalone: true,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerInputStartDirective),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateRangePickerInputStartDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL]
    }]
  }, {
    type: WlcmDatepickerTrigger
  }], null);
})();
const WLCM_DATE_RANGE_SELECTION_MODEL = new InjectionToken('WLCM_DATE_RANGE_SELECTION_MODEL');
const WLCM_DATE_RANGE_SELECTION_MODEL_PROVIDER = {
  provide: WLCM_DATE_RANGE_SELECTION_MODEL,
  useFactory: adapter => new MatRangeDateSelectionModel(adapter),
  deps: [DateAdapter]
};
class WlcmDateRangePicker {
  constructor(localSelectionModel, rangeSelectionStrategy) {
    this.localSelectionModel = localSelectionModel;
    this.rangeSelectionStrategy = rangeSelectionStrategy;
    this._isComplete = signal(false);
    this.isComplete = computed(() => this._isComplete());
    this.handleSelectionChanged();
  }
  calendarSelection(event) {
    const newSelection = this.rangeSelectionStrategy.selectionFinished(event.value, this.localSelectionModel.selection, event.event);
    this.localSelectionModel.updateSelection(newSelection, this);
  }
  calendarDragDrop(event) {
    this.localSelectionModel.updateSelection(event.value, this);
  }
  get selected() {
    return this.localSelectionModel.selection;
  }
  handleSelectionChanged() {
    this.localSelectionModel.selectionChanged.pipe(untilDestroyed(this)).subscribe(() => this._isComplete.set(this.localSelectionModel.isComplete()));
  }
}
class WlcmDateRangePickerBottomPanelComponent {
  constructor(picker) {
    this.picker = picker;
  }
  static {
    this.ɵfac = function WlcmDateRangePickerBottomPanelComponent_Factory(t) {
      return new (t || WlcmDateRangePickerBottomPanelComponent)(i0.ɵɵdirectiveInject(WlcmDateRangePicker));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDateRangePickerBottomPanelComponent,
      selectors: [["wlcm-date-range-picker-bottom-panel"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 1,
      consts: [[1, "wlcm-date-range-picker-bottom-panel"], ["type", "paddingless", 3, "clicked"], [3, "clicked", "isDisabled"]],
      template: function WlcmDateRangePickerBottomPanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "wlcm-button", 1);
          i0.ɵɵlistener("clicked", function WlcmDateRangePickerBottomPanelComponent_Template_wlcm_button_clicked_1_listener() {
            return ctx.picker.reset();
          });
          i0.ɵɵtext(2, "Reset");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "wlcm-button", 2);
          i0.ɵɵlistener("clicked", function WlcmDateRangePickerBottomPanelComponent_Template_wlcm_button_clicked_3_listener() {
            return ctx.picker.apply();
          });
          i0.ɵɵtext(4, " Apply ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("isDisabled", !ctx.picker.isComplete());
        }
      },
      dependencies: [CommonModule, WlcmButtonModule, i2$1.WlcmButtonComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDateRangePickerBottomPanelComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-date-range-picker-bottom-panel',
      standalone: true,
      imports: [CommonModule, WlcmButtonModule],
      template: "<div class=\"wlcm-date-range-picker-bottom-panel\">\n  <wlcm-button type=\"paddingless\" (clicked)=\"picker.reset()\">Reset</wlcm-button>\n\n  <wlcm-button (clicked)=\"picker.apply()\" [isDisabled]=\"!picker.isComplete()\">\n    Apply\n  </wlcm-button>\n</div>\n"
    }]
  }], () => [{
    type: WlcmDateRangePicker
  }], null);
})();
const WLCM_DATE_RANGE_PICKER_CONFIG = new InjectionToken('WLCM_DATE_RANGE_PICKER_CONFIG', {
  providedIn: 'root',
  factory: () => ({})
});
const WLCM_DATE_RANGE_PICKER_BP = new InjectionToken('WLCM_DATE_RANGE_PICKER_BP', {
  providedIn: 'root',
  factory: () => WlcmDateRangePickerBottomPanelComponent
});
class WlcmCalendarHeaderComponent {
  constructor(dateAdapter, dateFormats, calendar) {
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.calendar = calendar;
    this.WlcmIconName = WlcmIconName;
    this.next = input();
    this.previous = input();
    this.disableLeftArrow = input(false);
    this.disableRightArrow = input(false);
    this.displayLeftArrow = input(true);
    this.displayRightArrow = input(true);
  }
  previousClicked() {
    if (this.previous()) return this.previous()();
    this.calendar.activeDate = this.calendar.currentView == 'month' ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) : this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? -1 : -yearsPerPage);
  }
  nextClicked() {
    if (this.next()) return this.next()();
    this.calendar.activeDate = this.calendar.currentView == 'month' ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) : this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? 1 : yearsPerPage);
  }
  changeCalendarView() {
    if (this.calendar.currentView === 'multi-year') {
      this.calendar.currentView = 'month';
      return;
    }
    this.calendar.currentView = 'multi-year';
  }
  get periodLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
  }
  static {
    this.ɵfac = function WlcmCalendarHeaderComponent_Factory(t) {
      return new (t || WlcmCalendarHeaderComponent)(i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(MAT_DATE_FORMATS, 8), i0.ɵɵdirectiveInject(forwardRef(() => MatCalendar)));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmCalendarHeaderComponent,
      selectors: [["wlcm-calendar-header"]],
      hostAttrs: [1, "wlcm-calendar-header"],
      inputs: {
        next: [i0.ɵɵInputFlags.SignalBased, "next"],
        previous: [i0.ɵɵInputFlags.SignalBased, "previous"],
        disableLeftArrow: [i0.ɵɵInputFlags.SignalBased, "disableLeftArrow"],
        disableRightArrow: [i0.ɵɵInputFlags.SignalBased, "disableRightArrow"],
        displayLeftArrow: [i0.ɵɵInputFlags.SignalBased, "displayLeftArrow"],
        displayRightArrow: [i0.ɵɵInputFlags.SignalBased, "displayRightArrow"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 8,
      vars: 4,
      consts: [[1, "wlcm-calendar-header-button-container"], [1, "wlcm-calendar-header-button", "wlcm-calendar-header-button-left", 3, "disabled"], [1, "wlcm-calendar-header-change-view-button", 3, "click"], [1, "wlcm-calendar-header-period-label"], [3, "wlcmIcon"], [1, "wlcm-calendar-header-button", "wlcm-calendar-header-button-right", 3, "disabled"], [1, "wlcm-calendar-header-button", "wlcm-calendar-header-button-left", 3, "click", "disabled"], [1, "wlcm-calendar-header-button", "wlcm-calendar-header-button-right", 3, "click", "disabled"]],
      template: function WlcmCalendarHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, WlcmCalendarHeaderComponent_Conditional_1_Template, 2, 2, "button", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "button", 2);
          i0.ɵɵlistener("click", function WlcmCalendarHeaderComponent_Template_button_click_2_listener() {
            return ctx.changeCalendarView();
          });
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementContainer(5, 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "div", 0);
          i0.ɵɵtemplate(7, WlcmCalendarHeaderComponent_Conditional_7_Template, 2, 2, "button", 5);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.displayLeftArrow() ? 1 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", ctx.periodLabel, " ");
          i0.ɵɵadvance();
          i0.ɵɵproperty("wlcmIcon", ctx.WlcmIconName.CHEVRON_DOWN);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(7, ctx.displayRightArrow() ? 7 : -1);
        }
      },
      dependencies: [CommonModule, WlcmIconDirective]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmCalendarHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-calendar-header',
      host: {
        class: 'wlcm-calendar-header'
      },
      standalone: true,
      imports: [CommonModule, WlcmIconDirective],
      template: "<div class=\"wlcm-calendar-header-button-container\">\n  @if (displayLeftArrow()) {\n    <button\n      class=\"wlcm-calendar-header-button wlcm-calendar-header-button-left\"\n      [disabled]=\"disableLeftArrow()\"\n      (click)=\"previousClicked()\"\n    >\n      <ng-container [wlcmIcon]=\"WlcmIconName.CHEVRON_DOWN\"></ng-container>\n    </button>\n  }\n</div>\n\n<button\n  class=\"wlcm-calendar-header-change-view-button\"\n  (click)=\"changeCalendarView()\"\n>\n  <div class=\"wlcm-calendar-header-period-label\">\n    {{ periodLabel }}\n  </div>\n\n  <ng-container [wlcmIcon]=\"WlcmIconName.CHEVRON_DOWN\"></ng-container>\n</button>\n\n<div class=\"wlcm-calendar-header-button-container\">\n  @if (displayRightArrow()) {\n    <button\n      class=\"wlcm-calendar-header-button wlcm-calendar-header-button-right\"\n      [disabled]=\"disableRightArrow()\"\n      (click)=\"nextClicked()\"\n    >\n      <ng-container [wlcmIcon]=\"WlcmIconName.CHEVRON_DOWN\"></ng-container>\n    </button>\n  }\n</div>\n"
    }]
  }], () => [{
    type: i1$1.DateAdapter
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i2.MatCalendar,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => MatCalendar)]
    }]
  }], null);
})();
class WlcmCalendarDirective {
  static {
    this.ɵfac = function WlcmCalendarDirective_Factory(t) {
      return new (t || WlcmCalendarDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmCalendarDirective,
      selectors: [["", "wlcmCalendar", ""]],
      hostAttrs: [1, "wlcm-calendar"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmCalendarDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmCalendar]',
      host: {
        class: 'wlcm-calendar'
      },
      standalone: true
    }]
  }], null, null);
})();
let SingleCalendarRangePickerComponent = class SingleCalendarRangePickerComponent extends WlcmDateRangePicker {
  constructor(rangeSelectionStrategy, localSelectionModel, triggerSelectionModel, trigger, datepickerConfig, pickerBottomPanel, dateAdapter) {
    super(localSelectionModel, rangeSelectionStrategy);
    this.rangeSelectionStrategy = rangeSelectionStrategy;
    this.localSelectionModel = localSelectionModel;
    this.triggerSelectionModel = triggerSelectionModel;
    this.trigger = trigger;
    this.datepickerConfig = datepickerConfig;
    this.pickerBottomPanel = pickerBottomPanel;
    this.dateAdapter = dateAdapter;
    this.headerComponent = WlcmCalendarHeaderComponent;
    this.localSelectionModel.updateSelection(this.triggerSelectionModel.selection, this);
  }
  ngAfterViewInit() {
    this.bottomPanelContainer.createComponent(this.pickerBottomPanel).changeDetectorRef.detectChanges();
  }
  get minDate() {
    return this.datepickerConfig.minDate?.();
  }
  get maxDate() {
    return this.datepickerConfig.maxDate?.();
  }
  apply() {
    this.triggerSelectionModel.updateSelection(this.localSelectionModel.selection, this);
    this.trigger.close();
  }
  reset() {
    this.trigger.close();
  }
  static {
    this.ɵfac = function SingleCalendarRangePickerComponent_Factory(t) {
      return new (t || SingleCalendarRangePickerComponent)(i0.ɵɵdirectiveInject(MAT_DATE_RANGE_SELECTION_STRATEGY), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_SELECTION_MODEL), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_PICKER_BP), i0.ɵɵdirectiveInject(i1$1.DateAdapter));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SingleCalendarRangePickerComponent,
      selectors: [["wlcm-single-calendar-range-picker"]],
      viewQuery: function SingleCalendarRangePickerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bottomPanelContainer = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy
      }, {
        provide: WlcmDateRangePicker,
        useExisting: forwardRef(() => SingleCalendarRangePickerComponent)
      }, WLCM_DATE_RANGE_SELECTION_MODEL_PROVIDER]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 4,
      consts: [["bottomPanelContainer", ""], [1, "wlcm-date-range-picker-panel"], [1, "signal-calendar-range-picker-body"], ["wlcmCalendar", "", 3, "_userSelection", "_userDragDrop", "minDate", "maxDate", "selected", "headerComponent"]],
      template: function SingleCalendarRangePickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "mat-calendar", 3);
          i0.ɵɵlistener("_userSelection", function SingleCalendarRangePickerComponent_Template_mat_calendar__userSelection_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarSelection($event));
          })("_userDragDrop", function SingleCalendarRangePickerComponent_Template_mat_calendar__userDragDrop_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarDragDrop($event));
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementContainer(3, null, 0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("minDate", ctx.minDate)("maxDate", ctx.maxDate)("selected", ctx.selected)("headerComponent", ctx.headerComponent);
        }
      },
      dependencies: [CommonModule, MatDatepickerModule, i2.MatCalendar, WlcmCalendarDirective]
    });
  }
};
SingleCalendarRangePickerComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Object, MatDateSelectionModel, MatDateSelectionModel, WlcmDatepickerTrigger, Object, Type, DateAdapter])], SingleCalendarRangePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SingleCalendarRangePickerComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-single-calendar-range-picker',
      imports: [CommonModule, MatDatepickerModule, WlcmCalendarDirective],
      providers: [{
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy
      }, {
        provide: WlcmDateRangePicker,
        useExisting: forwardRef(() => SingleCalendarRangePickerComponent)
      }, WLCM_DATE_RANGE_SELECTION_MODEL_PROVIDER],
      standalone: true,
      template: "<div class=\"wlcm-date-range-picker-panel\">\n  <div class=\"signal-calendar-range-picker-body\">\n    <mat-calendar\n      wlcmCalendar\n      [minDate]=\"minDate\"\n      [maxDate]=\"maxDate\"\n      [selected]=\"selected\"\n      [headerComponent]=\"headerComponent\"\n      (_userSelection)=\"calendarSelection($event)\"\n      (_userDragDrop)=\"calendarDragDrop($event)\"\n    ></mat-calendar>\n  </div>\n\n  <ng-container #bottomPanelContainer></ng-container>\n</div>\n"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DATE_RANGE_SELECTION_STRATEGY]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_SELECTION_MODEL]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL]
    }]
  }, {
    type: WlcmDatepickerTrigger
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }, {
    type: i0.Type,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_PICKER_BP]
    }]
  }, {
    type: i1$1.DateAdapter
  }], {
    bottomPanelContainer: [{
      type: ViewChild,
      args: ['bottomPanelContainer', {
        read: ViewContainerRef
      }]
    }]
  });
})();
let DateRangePickerInputEndDirective = class DateRangePickerInputEndDirective extends WlcmDateRangePickerInputBase {
  constructor(element, dateAdapter, dateFormats, selectionModel, trigger) {
    super(element, dateAdapter, dateFormats, trigger);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.selectionModel = selectionModel;
    this.trigger = trigger;
    this.updateSelection = () => {
      let end = this.parseCurrentValue();
      this.selectionModel.updateSelection(new DateRange(this.selectionModel.selection.start, end), this);
    };
    this.handleSelectionChange();
  }
  validate() {
    const defaultErrors = super.validate();
    if (isValid(this.selectionModel.selection.start)) {
      const endDate = this.parseCurrentValue();
      if (endDate && isAfter(this.selectionModel.selection.start, endDate)) {
        return {
          [WlcmDateRangeValidation.StartExceedsEnd]: true,
          ...(defaultErrors ?? {})
        };
      }
    }
    return defaultErrors;
  }
  handleSelectionChange() {
    this.selectionModel.selectionChanged.pipe(untilDestroyed(this)).subscribe(({
      selection
    }) => {
      const validDate = this.dateAdapter.getValidDateOrNull(selection.end);
      this._changed?.(validDate);
      this.updateInputValue(validDate);
      this._validatorChanged?.();
    });
  }
  static {
    this.ɵfac = function DateRangePickerInputEndDirective_Factory(t) {
      return new (t || DateRangePickerInputEndDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(MAT_DATE_FORMATS, 8), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DateRangePickerInputEndDirective,
      selectors: [["", "wlcmDateRangePickerInputEnd", ""]],
      hostAttrs: [1, "wlcm-date-range-picker-input-field"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerInputEndDirective),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateRangePickerInputEndDirective),
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
};
DateRangePickerInputEndDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, DateAdapter, Object, MatDateSelectionModel, WlcmDatepickerTrigger])], DateRangePickerInputEndDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateRangePickerInputEndDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmDateRangePickerInputEnd]',
      host: {
        class: 'wlcm-date-range-picker-input-field'
      },
      standalone: true,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerInputEndDirective),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateRangePickerInputEndDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL]
    }]
  }, {
    type: WlcmDatepickerTrigger
  }], null);
})();
class DateRangePickerErrors {
  static throwNoStartInputError() {
    throw new Error('The input element with the wlcmDateRangePickerInputStart directive is missing');
  }
  static throwNoEndInputError() {
    throw new Error('The input element with the wlcmDateRangePickerInputEnd directive is missing');
  }
}
class WlcmDateRangePickerInputComponent extends WlcmDatepickerTrigger {
  constructor(overlay, viewContainerRef, selectionModel, formField, controlContainer) {
    super(overlay, viewContainerRef, selectionModel);
    this.overlay = overlay;
    this.viewContainerRef = viewContainerRef;
    this.selectionModel = selectionModel;
    this.formField = formField;
    this.controlContainer = controlContainer;
    this._focusStream$ = new BehaviorSubject(EMPTY);
    this._blurStream$ = new BehaviorSubject(EMPTY);
    this.focus$ = this._focusStream$.pipe(switchMap(stream => stream));
    this.blur$ = this._blurStream$.pipe(switchMap(stream => stream));
    this.componentType = SingleCalendarRangePickerComponent;
  }
  ngAfterContentInit() {
    this.checkInputs();
    this.attachFocusBlurListeners();
  }
  ngAfterViewInit() {
    this.connectedTo = this.formField.inputContainer;
  }
  isFocused() {
    return this.startInputElement === document.activeElement || this.endInputElement === document.activeElement || this.isOpen;
  }
  focus() {
    if (this.selectOnly()) return this.open();
    if (!this.isFocused()) this.startInputElement.focus();
  }
  get control() {
    return this.controlContainer?.control;
  }
  get startInputElement() {
    return this.startInput.element.nativeElement;
  }
  get endInputElement() {
    return this.endInput.element.nativeElement;
  }
  checkInputs() {
    if (!this.startInput) DateRangePickerErrors.throwNoStartInputError();
    if (!this.endInput) DateRangePickerErrors.throwNoEndInputError();
  }
  attachFocusBlurListeners() {
    const voidFn = () => {};
    const startInputFocus = fromEvent(this.startInputElement, 'focus');
    const endInputFocus = fromEvent(this.endInputElement, 'focus');
    const startInputBlur = fromEvent(this.startInputElement, 'blur');
    const endInputBlur = fromEvent(this.endInputElement, 'blur');
    const focusObservables = [startInputFocus, endInputFocus, this.opened];
    const blurObservables = [startInputBlur, endInputBlur, this.closed];
    this._focusStream$.next(merge(...focusObservables).pipe(map(voidFn)));
    this._blurStream$.next(merge(...blurObservables).pipe(map(() => this.control?.markAsTouched())));
  }
  static {
    this.ɵfac = function WlcmDateRangePickerInputComponent_Factory(t) {
      return new (t || WlcmDateRangePickerInputComponent)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL), i0.ɵɵdirectiveInject(WLCM_FORM_FIELD, 1), i0.ɵɵdirectiveInject(ControlContainer, 1));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDateRangePickerInputComponent,
      selectors: [["wlcm-date-range-picker-input"]],
      contentQueries: function WlcmDateRangePickerInputComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, DateRangePickerInputStartDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, DateRangePickerInputEndDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.startInput = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.endInput = _t.first);
        }
      },
      hostAttrs: [1, "wlcm-date-range-picker-input"],
      inputs: {
        componentType: "componentType"
      },
      exportAs: ["wlcmDateRangePickerInput"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([WLCM_FORM_CONTROL_PROVIDER, WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL_PROVIDER, {
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const input = inject(WlcmDateRangePickerInputComponent, {
            self: true
          });
          return {
            bind: () => input
          };
        }
      }, {
        provide: WlcmDatepickerTrigger,
        useExisting: forwardRef(() => WlcmDateRangePickerInputComponent)
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 6,
      vars: 0,
      consts: [[1, "wlcm-date-range-picker-inputs-container"], [1, "wlcm-date-range-picker-input-field-wrapper"], [1, "wlcm-date-range-picker-input-dash"]],
      template: function WlcmDateRangePickerInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "div", 2);
          i0.ɵɵelementStart(4, "div", 1);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd()();
        }
      },
      dependencies: [CommonModule, WlcmFormsModule],
      styles: [".wlcm-date-range-picker-inputs-container[_ngcontent-%COMP%]{display:flex;align-items:center}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDateRangePickerInputComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-date-range-picker-input',
      host: {
        class: 'wlcm-date-range-picker-input'
      },
      exportAs: 'wlcmDateRangePickerInput',
      standalone: true,
      imports: [CommonModule, WlcmFormsModule, DateRangePickerInputStartDirective, DateRangePickerInputEndDirective],
      providers: [WLCM_FORM_CONTROL_PROVIDER, WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL_PROVIDER, {
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const input = inject(WlcmDateRangePickerInputComponent, {
            self: true
          });
          return {
            bind: () => input
          };
        }
      }, {
        provide: WlcmDatepickerTrigger,
        useExisting: forwardRef(() => WlcmDateRangePickerInputComponent)
      }],
      template: "<div class=\"wlcm-date-range-picker-inputs-container\">\n  <div class=\"wlcm-date-range-picker-input-field-wrapper\">\n    <ng-content select=\"[wlcmDateRangePickerInputStart]\"></ng-content>\n  </div>\n\n  <div class=\"wlcm-date-range-picker-input-dash\"></div>\n\n  <div class=\"wlcm-date-range-picker-input-field-wrapper\">\n    <ng-content select=\"[wlcmDateRangePickerInputEnd]\"></ng-content>\n  </div>\n</div>\n",
      styles: [".wlcm-date-range-picker-inputs-container{display:flex;align-items:center}\n"]
    }]
  }], () => [{
    type: i1.Overlay
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Host
    }, {
      type: Inject,
      args: [WLCM_FORM_FIELD]
    }]
  }, {
    type: i3.ControlContainer,
    decorators: [{
      type: Host
    }, {
      type: Inject,
      args: [ControlContainer]
    }]
  }], {
    componentType: [{
      type: Input
    }],
    startInput: [{
      type: ContentChild,
      args: [DateRangePickerInputStartDirective]
    }],
    endInput: [{
      type: ContentChild,
      args: [DateRangePickerInputEndDirective]
    }]
  });
})();
class WlcmDataRangeCalendar {
  constructor(location, element, calendar, dateAdapter, datepickerConfig) {
    this.element = element;
    this.calendar = calendar;
    this.dateAdapter = dateAdapter;
    this.datepickerConfig = datepickerConfig;
    this.dateChange = output();
    this.viewChange = output();
    this.disabled = input(false);
    this.location = location;
    effect(() => {
      if (this.disabled()) {
        this.element.nativeElement.classList.add('wlcm-calendar-disabled');
      } else {
        this.element.nativeElement.classList.remove('wlcm-calendar-disabled');
      }
    });
    this.handleConfigValues();
  }
  get minDate() {
    return this.datepickerConfig.minDate?.();
  }
  get maxDate() {
    return this.datepickerConfig.maxDate?.();
  }
  prevMonth() {
    this.dateChange.emit(this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1));
  }
  nextMonth() {
    this.dateChange.emit(this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1));
  }
  prevYear() {
    this.dateChange.emit(this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? -1 : -yearsPerPage));
  }
  nextYear() {
    this.dateChange.emit(this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? 1 : yearsPerPage));
  }
  handleConfigValues() {
    this.calendar.dateFilter = date => {
      if (this.minDate && isBefore(startOfDay(date), startOfDay(this.minDate))) return false;
      if (this.maxDate && isAfter(startOfDay(date), startOfDay(this.maxDate))) return false;
      return true;
    };
  }
  static {
    this.ɵfac = function WlcmDataRangeCalendar_Factory(t) {
      i0.ɵɵinvalidFactory();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmDataRangeCalendar,
      inputs: {
        disabled: [i0.ɵɵInputFlags.SignalBased, "disabled"]
      },
      outputs: {
        dateChange: "dateChange",
        viewChange: "viewChange"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDataRangeCalendar, [{
    type: Directive
  }], () => [{
    type: undefined
  }, {
    type: i0.ElementRef
  }, {
    type: i2.MatCalendar
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined
  }], null);
})();
let WlcmLeftCalendarDirective = class WlcmLeftCalendarDirective extends WlcmDataRangeCalendar {
  constructor(element, dateAdapter, calendar, datepickerConfig) {
    super('left', element, calendar, dateAdapter, datepickerConfig);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.calendar = calendar;
    this.datepickerConfig = datepickerConfig;
    this.handleYearChange();
    this.handleMonthChange();
  }
  handleYearChange() {
    this.calendar.yearSelected.pipe(untilDestroyed(this)).subscribe(date => this.dateChange.emit(date));
  }
  handleMonthChange() {
    this.calendar.monthSelected.pipe(untilDestroyed(this)).subscribe(date => this.dateChange.emit(date));
  }
  static {
    this.ɵfac = function WlcmLeftCalendarDirective_Factory(t) {
      return new (t || WlcmLeftCalendarDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(forwardRef(() => MatCalendar)), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmLeftCalendarDirective,
      selectors: [["", "wlcmLeftCalendar", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WlcmDataRangeCalendar,
        useExisting: forwardRef(() => WlcmLeftCalendarDirective)
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
};
WlcmLeftCalendarDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, DateAdapter, MatCalendar, Object])], WlcmLeftCalendarDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmLeftCalendarDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmLeftCalendar]',
      standalone: true,
      providers: [{
        provide: WlcmDataRangeCalendar,
        useExisting: forwardRef(() => WlcmLeftCalendarDirective)
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: i2.MatCalendar,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => MatCalendar)]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }], null);
})();
let WlcmRightCalendarDirective = class WlcmRightCalendarDirective extends WlcmDataRangeCalendar {
  constructor(element, dateAdapter, calendar, datepickerConfig) {
    super('right', element, calendar, dateAdapter, datepickerConfig);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.calendar = calendar;
    this.datepickerConfig = datepickerConfig;
    this.handleYearChange();
    this.handleMonthChange();
  }
  handleYearChange() {
    this.calendar.yearSelected.pipe(untilDestroyed(this)).subscribe(date => this.dateChange.emit(this.dateAdapter.addCalendarMonths(date, -1)));
  }
  handleMonthChange() {
    this.calendar.monthSelected.pipe(untilDestroyed(this)).subscribe(date => this.dateChange.emit(this.dateAdapter.addCalendarMonths(date, -1)));
  }
  nextMonth() {
    this.dateChange.emit(this.calendar.activeDate);
  }
  prevYear() {
    this.dateChange.emit(this.dateAdapter.addCalendarMonths(this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? -1 : -yearsPerPage), -1));
  }
  nextYear() {
    this.dateChange.emit(this.dateAdapter.addCalendarMonths(this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.calendar.currentView == 'year' ? 1 : yearsPerPage), -1));
  }
  static {
    this.ɵfac = function WlcmRightCalendarDirective_Factory(t) {
      return new (t || WlcmRightCalendarDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(forwardRef(() => MatCalendar)), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmRightCalendarDirective,
      selectors: [["", "wlcmRightCalendar", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WlcmDataRangeCalendar,
        useExisting: forwardRef(() => WlcmRightCalendarDirective)
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
};
WlcmRightCalendarDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, DateAdapter, MatCalendar, Object])], WlcmRightCalendarDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmRightCalendarDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmRightCalendar]',
      standalone: true,
      providers: [{
        provide: WlcmDataRangeCalendar,
        useExisting: forwardRef(() => WlcmRightCalendarDirective)
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: i2.MatCalendar,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => MatCalendar)]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }], null);
})();
class WlcmDateRangeCalendarHeaderComponent {
  constructor(rangeCalendar, dateFormats, calendar, datepickerConfig) {
    this.rangeCalendar = rangeCalendar;
    this.dateFormats = dateFormats;
    this.calendar = calendar;
    this.datepickerConfig = datepickerConfig;
    this.WlcmIconName = WlcmIconName;
    this.previous = () => {
      if (this.isPreviousDisabled) return;
      if (this.calendar.currentView !== 'month') {
        return this.rangeCalendar.prevYear();
      }
      return this.rangeCalendar.prevMonth();
    };
    this.next = () => {
      if (this.isNextDisabled) return;
      if (this.calendar.currentView !== 'month') {
        return this.rangeCalendar.nextYear();
      }
      return this.rangeCalendar.nextMonth();
    };
  }
  get minDate() {
    return this.datepickerConfig.minDate?.();
  }
  get maxDate() {
    return this.datepickerConfig.maxDate?.();
  }
  get isPreviousDisabled() {
    if (!this.minDate) return false;
    let previousPeriod = endOfMonth(add(this.calendar.activeDate, {
      months: -1
    }));
    if (this.calendar.currentView !== 'month') {
      previousPeriod = add(this.calendar.activeDate, {
        years: -1
      });
    }
    return isBefore(previousPeriod, this.minDate);
  }
  get isNextDisabled() {
    if (!this.maxDate) return false;
    let nextPeriod = startOfMonth(add(this.calendar.activeDate, {
      months: 1
    }));
    if (this.calendar.currentView !== 'month') {
      nextPeriod = add(this.calendar.activeDate, {
        years: 1
      });
    }
    return isBefore(this.maxDate, nextPeriod);
  }
  get canDisplayLeftArrow() {
    return !this.rangeCalendar || this.rangeCalendar.location === 'left' || this.calendar.currentView !== 'month';
  }
  get canDisplayRightArrow() {
    return !this.rangeCalendar || this.rangeCalendar.location === 'right' || this.calendar.currentView !== 'month';
  }
  static {
    this.ɵfac = function WlcmDateRangeCalendarHeaderComponent_Factory(t) {
      return new (t || WlcmDateRangeCalendarHeaderComponent)(i0.ɵɵdirectiveInject(WlcmDataRangeCalendar, 8), i0.ɵɵdirectiveInject(MAT_DATE_FORMATS, 8), i0.ɵɵdirectiveInject(forwardRef(() => MatCalendar)), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDateRangeCalendarHeaderComponent,
      selectors: [["wlcm-date-range-calendar-header"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 6,
      consts: [[3, "next", "previous", "disableLeftArrow", "disableRightArrow", "displayLeftArrow", "displayRightArrow"]],
      template: function WlcmDateRangeCalendarHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "wlcm-calendar-header", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("next", ctx.next)("previous", ctx.previous)("disableLeftArrow", ctx.isPreviousDisabled)("disableRightArrow", ctx.isNextDisabled)("displayLeftArrow", ctx.canDisplayLeftArrow && !ctx.rangeCalendar.disabled())("displayRightArrow", ctx.canDisplayRightArrow && !ctx.rangeCalendar.disabled());
        }
      },
      dependencies: [CommonModule, WlcmCalendarHeaderComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDateRangeCalendarHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-date-range-calendar-header',
      standalone: true,
      imports: [CommonModule, WlcmCalendarHeaderComponent, WlcmIconDirective],
      template: "<wlcm-calendar-header\n  [next]=\"next\"\n  [previous]=\"previous\"\n  [disableLeftArrow]=\"isPreviousDisabled\"\n  [disableRightArrow]=\"isNextDisabled\"\n  [displayLeftArrow]=\"canDisplayLeftArrow && !rangeCalendar.disabled()\"\n  [displayRightArrow]=\"canDisplayRightArrow && !rangeCalendar.disabled()\"\n></wlcm-calendar-header>\n"
    }]
  }], () => [{
    type: WlcmDataRangeCalendar,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i2.MatCalendar,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => MatCalendar)]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }], null);
})();
let DoubleCalendarRangePickerComponent = class DoubleCalendarRangePickerComponent extends WlcmDateRangePicker {
  constructor(rangeConfig, rangeSelectionStrategy, localSelectionModel, triggerSelectionModel, trigger, datepickerConfig, pickerBottomPanel, dateAdapter) {
    super(localSelectionModel, rangeSelectionStrategy);
    this.rangeConfig = rangeConfig;
    this.rangeSelectionStrategy = rangeSelectionStrategy;
    this.localSelectionModel = localSelectionModel;
    this.triggerSelectionModel = triggerSelectionModel;
    this.trigger = trigger;
    this.datepickerConfig = datepickerConfig;
    this.pickerBottomPanel = pickerBottomPanel;
    this.dateAdapter = dateAdapter;
    this.headerComponent = WlcmDateRangeCalendarHeaderComponent;
    this._isViewInitialized = signal(false);
    this.startDate = signal(new Date());
    this.leftCalendarYearViewActivated = signal(false);
    this.rightCalendarYearViewActivated = signal(false);
    this.localSelectionModel.updateSelection(this.triggerSelectionModel.selection, this);
    if (this.triggerSelectionModel.selection.start) {
      this.startDate.set(this.triggerSelectionModel.selection.start);
    }
    if (this.rangeConfig.headerComponent) {
      this.headerComponent = this.rangeConfig.headerComponent;
    }
    effect(() => {
      if (!this._isViewInitialized()) return;
      const startDate = this.startDate();
      this.leftCalendar.activeDate = startDate;
      this.rightCalendar.activeDate = this.dateAdapter.addCalendarMonths(startDate, 1);
      this.rightCalendar.startAt = this.rightCalendar.activeDate;
    });
  }
  ngAfterViewInit() {
    this._isViewInitialized.set(true);
    this.leftCalendar.viewChanged.pipe(untilDestroyed(this)).subscribe(view => {
      this.leftCalendarYearViewActivated.set(view !== 'month');
    });
    this.rightCalendar.viewChanged.pipe(untilDestroyed(this)).subscribe(view => {
      this.rightCalendarYearViewActivated.set(view !== 'month');
    });
    this.bottomPanelContainer.createComponent(this.pickerBottomPanel).changeDetectorRef.detectChanges();
    this.handleRightCalendarPreviewChange();
  }
  apply() {
    this.triggerSelectionModel.updateSelection(this.localSelectionModel.selection, this);
    this.trigger.close();
  }
  reset() {
    this.trigger.close();
  }
  get leftCalendarActiveCell() {
    return this.leftCalendarElement.nativeElement.querySelector('.mat-calendar-body-active');
  }
  handleRightCalendarPreviewChange() {
    const viewChanged = new Subject();
    this.rightCalendar.viewChanged.pipe(untilDestroyed(this)).pipe(tap(() => viewChanged.next())).pipe(startWith(this.rightCalendar.currentView)).subscribe(view => {
      if (view !== 'month') return;
      this.rightCalendar.monthView._matCalendarBody.previewChange.pipe(takeUntil(viewChanged), untilDestroyed(this)).subscribe(() => {
        if (this.leftCalendar.currentView !== 'month' || !this.leftCalendar.monthView._rangeStart) return;
        this.leftCalendar.monthView._previewStart = this.rightCalendar.monthView._previewStart;
        this.leftCalendar.monthView._previewEnd = this.rightCalendar.monthView._previewEnd;
        const activeCell = this.leftCalendarActiveCell;
        if (activeCell && activeCell === document.activeElement) activeCell.blur();
        this.leftCalendar.monthView._changeDetectorRef.markForCheck();
      });
    });
  }
  static {
    this.ɵfac = function DoubleCalendarRangePickerComponent_Factory(t) {
      return new (t || DoubleCalendarRangePickerComponent)(i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_PICKER_CONFIG, 8), i0.ɵɵdirectiveInject(MAT_DATE_RANGE_SELECTION_STRATEGY), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_SELECTION_MODEL), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG), i0.ɵɵdirectiveInject(WLCM_DATE_RANGE_PICKER_BP), i0.ɵɵdirectiveInject(i1$1.DateAdapter));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DoubleCalendarRangePickerComponent,
      selectors: [["wlcm-double-calendar-range-picker"]],
      viewQuery: function DoubleCalendarRangePickerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(WlcmLeftCalendarDirective, 5, MatCalendar);
          i0.ɵɵviewQuery(WlcmRightCalendarDirective, 5, MatCalendar);
          i0.ɵɵviewQuery(WlcmLeftCalendarDirective, 5, ElementRef);
          i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
          i0.ɵɵviewQuery(MatMonthView, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.leftCalendar = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rightCalendar = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.leftCalendarElement = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bottomPanelContainer = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.monthView = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy
      }, {
        provide: WlcmDateRangePicker,
        useExisting: forwardRef(() => DoubleCalendarRangePickerComponent)
      }, WLCM_DATE_RANGE_SELECTION_MODEL_PROVIDER]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 6,
      consts: [["bottomPanelContainer", ""], [1, "wlcm-date-range-picker-panel"], [1, "double-calendar-range-picker-body"], ["wlcmCalendar", "", "wlcmLeftCalendar", "", 3, "_userSelection", "_userDragDrop", "dateChange", "selected", "disabled", "headerComponent"], ["wlcmCalendar", "", "wlcmRightCalendar", "", 3, "_userSelection", "_userDragDrop", "dateChange", "selected", "disabled", "headerComponent"]],
      template: function DoubleCalendarRangePickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "mat-calendar", 3);
          i0.ɵɵlistener("_userSelection", function DoubleCalendarRangePickerComponent_Template_mat_calendar__userSelection_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarSelection($event));
          })("_userDragDrop", function DoubleCalendarRangePickerComponent_Template_mat_calendar__userDragDrop_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarDragDrop($event));
          })("dateChange", function DoubleCalendarRangePickerComponent_Template_mat_calendar_dateChange_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.startDate.set($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "mat-calendar", 4);
          i0.ɵɵlistener("_userSelection", function DoubleCalendarRangePickerComponent_Template_mat_calendar__userSelection_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarSelection($event));
          })("_userDragDrop", function DoubleCalendarRangePickerComponent_Template_mat_calendar__userDragDrop_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.calendarDragDrop($event));
          })("dateChange", function DoubleCalendarRangePickerComponent_Template_mat_calendar_dateChange_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.startDate.set($event));
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementContainer(4, null, 0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("selected", ctx.selected)("disabled", ctx.rightCalendarYearViewActivated())("headerComponent", ctx.headerComponent);
          i0.ɵɵadvance();
          i0.ɵɵproperty("selected", ctx.selected)("disabled", ctx.leftCalendarYearViewActivated())("headerComponent", ctx.headerComponent);
        }
      },
      dependencies: [CommonModule, MatDatepickerModule, i2.MatCalendar, WlcmCalendarDirective, WlcmLeftCalendarDirective, WlcmRightCalendarDirective]
    });
  }
};
DoubleCalendarRangePickerComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Object, Object, MatDateSelectionModel, MatDateSelectionModel, WlcmDatepickerTrigger, Object, Type, DateAdapter])], DoubleCalendarRangePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DoubleCalendarRangePickerComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-double-calendar-range-picker',
      imports: [CommonModule, MatDatepickerModule, WlcmCalendarDirective, WlcmLeftCalendarDirective, WlcmRightCalendarDirective],
      providers: [{
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy
      }, {
        provide: WlcmDateRangePicker,
        useExisting: forwardRef(() => DoubleCalendarRangePickerComponent)
      }, WLCM_DATE_RANGE_SELECTION_MODEL_PROVIDER],
      standalone: true,
      template: "<div class=\"wlcm-date-range-picker-panel\">\n  <div class=\"double-calendar-range-picker-body\">\n    <mat-calendar\n      wlcmCalendar\n      wlcmLeftCalendar\n      [selected]=\"selected\"\n      [disabled]=\"rightCalendarYearViewActivated()\"\n      [headerComponent]=\"headerComponent\"\n      (_userSelection)=\"calendarSelection($event)\"\n      (_userDragDrop)=\"calendarDragDrop($event)\"\n      (dateChange)=\"startDate.set($event)\"\n    ></mat-calendar>\n\n    <mat-calendar\n      wlcmCalendar\n      wlcmRightCalendar\n      [selected]=\"selected\"\n      [disabled]=\"leftCalendarYearViewActivated()\"\n      [headerComponent]=\"headerComponent\"\n      (_userSelection)=\"calendarSelection($event)\"\n      (_userDragDrop)=\"calendarDragDrop($event)\"\n      (dateChange)=\"startDate.set($event)\"\n    ></mat-calendar>\n  </div>\n\n  <ng-container #bottomPanelContainer></ng-container>\n</div>\n"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WLCM_DATE_RANGE_PICKER_CONFIG]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DATE_RANGE_SELECTION_STRATEGY]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_SELECTION_MODEL]
    }]
  }, {
    type: i2.MatDateSelectionModel,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_TRIGGER_SELECTION_MODEL]
    }]
  }, {
    type: WlcmDatepickerTrigger
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }, {
    type: i0.Type,
    decorators: [{
      type: Inject,
      args: [WLCM_DATE_RANGE_PICKER_BP]
    }]
  }, {
    type: i1$1.DateAdapter
  }], {
    leftCalendar: [{
      type: ViewChild,
      args: [WlcmLeftCalendarDirective, {
        read: MatCalendar
      }]
    }],
    rightCalendar: [{
      type: ViewChild,
      args: [WlcmRightCalendarDirective, {
        read: MatCalendar
      }]
    }],
    leftCalendarElement: [{
      type: ViewChild,
      args: [WlcmLeftCalendarDirective, {
        read: ElementRef
      }]
    }],
    bottomPanelContainer: [{
      type: ViewChild,
      args: ['bottomPanelContainer', {
        read: ViewContainerRef
      }]
    }],
    monthView: [{
      type: ViewChild,
      args: [MatMonthView]
    }]
  });
})();
let WlcmDatepickerInputDirective = class WlcmDatepickerInputDirective extends WlcmDatepickerInputBase {
  constructor(element, dateAdapter, dateFormats, selectionModel, trigger) {
    super(element, dateAdapter, dateFormats, trigger);
    this.element = element;
    this.dateAdapter = dateAdapter;
    this.dateFormats = dateFormats;
    this.selectionModel = selectionModel;
    this.trigger = trigger;
    this.handleSelectionChange();
    this.handleClosePanel();
  }
  updateSelection() {
    let date = this.parseCurrentValue();
    this.selectionModel.updateSelection(date, this);
  }
  handleClosePanel() {
    this.trigger.closed.pipe(untilDestroyed(this)).subscribe(() => this._touched?.());
  }
  handleSelectionChange() {
    this.selectionModel.selectionChanged.pipe(untilDestroyed(this)).subscribe(({
      selection
    }) => {
      this.updateInputValue(selection);
      this._changed?.(selection);
      this._touched?.();
    });
  }
  static {
    this.ɵfac = function WlcmDatepickerInputDirective_Factory(t) {
      return new (t || WlcmDatepickerInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.DateAdapter), i0.ɵɵdirectiveInject(MAT_DATE_FORMATS), i0.ɵɵdirectiveInject(i2.MatDateSelectionModel), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmDatepickerInputDirective,
      selectors: [["", "wlcmDatepickerInput", ""]],
      hostAttrs: [1, "wlcm-form-field-input"],
      exportAs: ["wlcmDatepickerInput"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmDatepickerInputDirective),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmDatepickerInputDirective),
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
};
WlcmDatepickerInputDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, DateAdapter, Object, MatDateSelectionModel, WlcmDatepickerTrigger])], WlcmDatepickerInputDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerInputDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmDatepickerInput]',
      exportAs: 'wlcmDatepickerInput',
      host: {
        class: 'wlcm-form-field-input'
      },
      standalone: true,
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmDatepickerInputDirective),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmDatepickerInputDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.DateAdapter
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i2.MatDateSelectionModel
  }, {
    type: WlcmDatepickerTrigger
  }], null);
})();
class WlcmDatepickerPanelComponent {
  constructor(selectionModel, trigger, config) {
    this.selectionModel = selectionModel;
    this.trigger = trigger;
    this.config = config;
    this.headerComponent = WlcmCalendarHeaderComponent;
    this.matCalendar = viewChild(MatCalendar);
  }
  select(date) {
    this.selectionModel.updateSelection(date, this);
    this.trigger.close();
  }
  static {
    this.ɵfac = function WlcmDatepickerPanelComponent_Factory(t) {
      return new (t || WlcmDatepickerPanelComponent)(i0.ɵɵdirectiveInject(i2.MatDateSelectionModel), i0.ɵɵdirectiveInject(WlcmDatepickerTrigger), i0.ɵɵdirectiveInject(WLCM_DATEPICKER_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDatepickerPanelComponent,
      selectors: [["wlcm-datepicker-panel"]],
      viewQuery: function WlcmDatepickerPanelComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.matCalendar, MatCalendar, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostAttrs: [1, "wlcm-datepicker-panel"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 5,
      consts: [["wlcmCalendar", "", 3, "selectedChange", "minDate", "maxDate", "startAt", "selected", "headerComponent"]],
      template: function WlcmDatepickerPanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "mat-calendar", 0);
          i0.ɵɵlistener("selectedChange", function WlcmDatepickerPanelComponent_Template_mat_calendar_selectedChange_0_listener($event) {
            return ctx.select($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("minDate", ctx.config.minDate == null ? null : ctx.config.minDate())("maxDate", ctx.config.maxDate == null ? null : ctx.config.maxDate())("startAt", ctx.selectionModel.selection)("selected", ctx.selectionModel.selection)("headerComponent", ctx.headerComponent);
        }
      },
      dependencies: [CommonModule, MatDatepickerModule, i2.MatCalendar, WlcmCalendarDirective]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerPanelComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-datepicker-panel',
      host: {
        class: 'wlcm-datepicker-panel'
      },
      standalone: true,
      imports: [CommonModule, MatDatepickerModule, WlcmCalendarDirective],
      template: "<mat-calendar\n  wlcmCalendar\n  [minDate]=\"config.minDate?.()\"\n  [maxDate]=\"config.maxDate?.()\"\n  [startAt]=\"selectionModel.selection\"\n  [selected]=\"selectionModel.selection\"\n  [headerComponent]=\"headerComponent\"\n  (selectedChange)=\"select($event)\"\n></mat-calendar>\n"
    }]
  }], () => [{
    type: i2.MatDateSelectionModel
  }, {
    type: WlcmDatepickerTrigger
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_DATEPICKER_CONFIG]
    }]
  }], null);
})();
class WlcmDatepickerInputContainerComponent extends WlcmDatepickerTrigger {
  constructor(overlay, viewContainerRef, selectionModel, formField) {
    super(overlay, viewContainerRef, selectionModel);
    this.overlay = overlay;
    this.viewContainerRef = viewContainerRef;
    this.selectionModel = selectionModel;
    this.formField = formField;
    this._focusStream$ = new BehaviorSubject(EMPTY);
    this._blurStream$ = new BehaviorSubject(EMPTY);
    this.focus$ = this._focusStream$.pipe(switchMap(stream => stream));
    this.blur$ = this._blurStream$.pipe(switchMap(stream => stream));
    this.inputRef = contentChild(WlcmDatepickerInputDirective, {
      read: ElementRef
    });
    this.componentType = WlcmDatepickerPanelComponent;
    this.focus = () => {
      if (this.selectOnly()) return this.open();
      this.inputElement?.focus();
    };
    this.isFocused = () => {
      return this.inputElement === document.activeElement || this.isOpen;
    };
  }
  ngAfterContentInit() {
    this.attachFocusBlurListeners();
  }
  ngAfterViewInit() {
    this.connectedTo = this.formField.inputContainer;
  }
  get inputElement() {
    return this.inputRef()?.nativeElement;
  }
  attachFocusBlurListeners() {
    const voidFn = () => {};
    const inputFocus = fromEvent(this.inputElement, 'focus');
    const inputBlur = fromEvent(this.inputElement, 'blur');
    const focusObservables = [inputFocus, this.opened];
    const blurObservables = [inputBlur, this.closed];
    this._focusStream$.next(merge(...focusObservables).pipe(map(voidFn)));
    this._blurStream$.next(merge(...blurObservables).pipe(map(voidFn)));
  }
  static {
    this.ɵfac = function WlcmDatepickerInputContainerComponent_Factory(t) {
      return new (t || WlcmDatepickerInputContainerComponent)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i2.MatDateSelectionModel), i0.ɵɵdirectiveInject(WLCM_FORM_FIELD, 1));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDatepickerInputContainerComponent,
      selectors: [["wlcm-datepicker-input-container"]],
      contentQueries: function WlcmDatepickerInputContainerComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.inputRef, WlcmDatepickerInputDirective, 5, ElementRef);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostAttrs: [1, "wlcm-datepicker-input-container"],
      exportAs: ["wlcmDatepicker"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const input = inject(WlcmDatepickerInputContainerComponent, {
            self: true
          });
          return {
            bind: () => input
          };
        }
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent),
        multi: true
      }, {
        provide: WlcmDatepickerTrigger,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent)
      }, {
        provide: MatDateSelectionModel,
        useClass: MatSingleDateSelectionModel
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 1,
      vars: 0,
      template: function WlcmDatepickerInputContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerInputContainerComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-datepicker-input-container',
      host: {
        class: 'wlcm-datepicker-input-container'
      },
      exportAs: 'wlcmDatepicker',
      standalone: true,
      imports: [CommonModule],
      providers: [{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const input = inject(WlcmDatepickerInputContainerComponent, {
            self: true
          });
          return {
            bind: () => input
          };
        }
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent),
        multi: true
      }, {
        provide: WlcmDatepickerTrigger,
        useExisting: forwardRef(() => WlcmDatepickerInputContainerComponent)
      }, {
        provide: MatDateSelectionModel,
        useClass: MatSingleDateSelectionModel
      }],
      template: "<ng-content select=\"[wlcmDatepickerInput]\"></ng-content>\n"
    }]
  }], () => [{
    type: i1.Overlay
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i2.MatDateSelectionModel
  }, {
    type: undefined,
    decorators: [{
      type: Host
    }, {
      type: Inject,
      args: [WLCM_FORM_FIELD]
    }]
  }], null);
})();
function WlcmDateRangeInputValidator() {
  return control => {
    const formGroup = control;
    let errors = {};
    for (const childControl of Object.values(formGroup.controls)) {
      errors = {
        ...errors,
        ...childControl.errors
      };
    }
    errors = Object.keys(errors).length > 0 ? errors : null;
    if (errors instanceof Object && errors[WlcmDateValidation.Format] && errors['required']) {
      errors['required'] = false;
    }
    return errors;
  };
}
const publicComponents = [WlcmDatepickerInputContainerComponent, WlcmDateRangePickerInputComponent];
const publicDirectives = [WlcmDatepickerInputDirective, DateRangePickerInputStartDirective, DateRangePickerInputEndDirective];
class WlcmDatepickerModule {
  static {
    this.ɵfac = function WlcmDatepickerModule_Factory(t) {
      return new (t || WlcmDatepickerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WlcmDatepickerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...WLCM_DEFAULT_DATEPICKER_PROVIDERS],
      imports: [CommonModule, publicComponents]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDatepickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ...publicComponents, ...publicDirectives],
      exports: [...publicComponents, ...publicDirectives],
      providers: [...WLCM_DEFAULT_DATEPICKER_PROVIDERS]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DateRangePickerInputEndDirective, DateRangePickerInputStartDirective, DoubleCalendarRangePickerComponent, SingleCalendarRangePickerComponent, WLCM_DATEPICKER_CONFIG, WlcmDateRangeInputValidator, WlcmDateRangePicker, WlcmDateRangePickerInputComponent, WlcmDateRangeValidation, WlcmDateValidation, WlcmDatepickerInputContainerComponent, WlcmDatepickerInputDirective, WlcmDatepickerModule };
